"use client";

import React, { createContext, useContext } from "react";
import { Clock, Diamond } from "lucide-react";
import Img from "next/image";
import { TrustBadgeIcon } from "@/components/icons/trust-badge-icon";
import { ItemRarity, ItemType, ItemSubType, GamePlayMode } from "@/types";
import { Header } from "./components/header";
import { Details } from "./components/details";
import { List } from "./components/list";

import { cn } from "@repo/ui/utils";
import { twMerge } from "tailwind-merge";

interface SeparatorProps {
  className?: string;
  variant?: "default" | "item-power";
}

interface ItemCardContextType {
  rarity: ItemRarity;
  type: ItemType;
  subType: ItemSubType;
}

const ItemCardContext = createContext({} as ItemCardContextType);

export function ItemCard({
  rarity,
  type,
  subType,
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement> & {
  rarity: ItemRarity;
  type: ItemType;
  subType: ItemSubType;
}) {
  const borderImage: Record<ItemRarity, React.CSSProperties> = {
    magic: {
      borderImage: "url('/assets/card/magic-border.png')",
    },
    rare: {
      borderImage: "url('/assets/card/rare-border.png')",
    },
    legendary: {
      borderImage: "url('/assets/card/legendary-border.png')",
    },
    unique: {
      borderImage: "url('/assets/card/unique-border.png')",
    },
    common: {
      borderImage: "url('/assets/card/common-border.png')",
    },
  };

  const insetBorderImage: Record<ItemRarity, React.CSSProperties> = {
    magic: {
      borderImage: "url('/assets/card/magic-inner-border.svg')",
    },
    rare: {
      borderImage: "url('/assets/card/rare-inner-border.svg')",
    },
    legendary: {
      borderImage: "url('/assets/card/legendary-inner-border.svg')",
    },
    unique: {
      borderImage: "url('/assets/card/unique-inner-border.svg')",
    },
    common: {
      borderImage: "url('/assets/card/common-inner-border.svg')",
    },
  };

  return (
    <ItemCardContext.Provider value={{ rarity, type, subType }}>
      <div
        className={cn(
          "relative grid min-h-[380px] w-[267px] grid-rows-[auto_1fr_auto]",
          className,
        )}
        style={{
          ...borderImage[rarity],
          borderImageWidth: "auto",
          borderImageSlice: "180 310 160 320",
        }}
      >
        <div
          className="absolute"
          style={{
            ...insetBorderImage[rarity],
            borderWidth: "1px",
            borderImageSlice: "0.1 fill",
          }}
        />
        {children}
      </div>
    </ItemCardContext.Provider>
  );
}

ItemCard.Header = Header;
ItemCard.Body = Body;
ItemCard.Footer = Footer;

ItemCard.Details = Details;
ItemCard.Image = Image;
ItemCard.Title = Title;
ItemCard.ItemType = TextItemType;
ItemCard.Icon = Icon;
ItemCard.Text1 = Text1;
ItemCard.ListedAt = ListedAt;
ItemCard.User = User;
ItemCard.UserAuction = UserAuction;
ItemCard.List = List;
ItemCard.RequirementLevel = RequirementLevel;
ItemCard.RequirementLevelAuction = RequirementLevelAuction;
ItemCard.Separator = Separator;
Footer.InformationsUser = InformationsUser;

function Body({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="px-[7%]">{children}</div>;
}

function Footer({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="px-[7%] pb-3">{children}</div>;
}

function Image() {
  const { type, subType } = useContext(ItemCardContext);
  return (
    <div className="absolute right-[6%] top-[15%]">
      <div className="relative h-[72px] w-[60px]">
        <Img alt="" fill src={`/assets/${type}/${subType}.webp`} />
      </div>
    </div>
  );
}

function Title({ children }: React.PropsWithChildren): JSX.Element {
  const { rarity } = useContext(ItemCardContext);

  return (
    <h2
      className={cn("font-diablo text-[16px]", {
        "text-legendary": rarity === "legendary",
        "text-unique": rarity === "unique",
        "text-rare": rarity === "rare",
        "text-magic": rarity === "magic",
      })}
    >
      {children}
    </h2>
  );
}

function TextItemType({ children }: React.PropsWithChildren): JSX.Element {
  return <h3 className="font-diablo text-[12px] text-gray-100">{children}</h3>;
}

function Icon() {
  return (
    <div>
      <TrustBadgeIcon />
    </div>
  );
}

function Text1({ children }: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className="mb-1 font-diablo text-[12px] font-bold text-gray-100">
      {children}
    </p>
  );
}

function RequirementLevel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={twMerge(
        `m-none pt-1 text-end font-diablo text-[10px] font-bold text-gray-500`,
        className,
      )}
    >
      {children}
    </p>
  );
}

function RequirementLevelAuction({
  children,
  type,
  className,
}: {
  children: React.ReactNode;
  type: GamePlayMode;
  className?: string;
}) {
  const imageSrc: Record<GamePlayMode, string> = {
    seasonal_hardcore: "/images/modes/seasonal_hardcore.webp",
    seasonal_softcore: "/images/modes/seasonal_softcore.webp",
    eternal_hardcore: "/images/modes/eternal_hardcore.png",
    eternal_softcore: "/images/modes/eternal_softcore.png",
  };

  return (
    <div
      className={twMerge(
        "m-none mt-4 flex items-end justify-between text-end font-diablo text-[12px] font-bold text-[#565656]",
        className,
      )}
    >
      <Img alt="image" height={14} src={imageSrc[type]} width={20} />
      {children}
    </div>
  );
}

function User({ children }: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className="flex items-center font-diablo text-[12px] font-bold text-gray-50">
      <i className="mr-1 block h-[10px] w-[10px] rounded-full bg-green-100" />
      {children}
    </p>
  );
}

function UserAuction({ children }: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className="flex items-center gap-2 font-diablo font-[16px] font-bold text-gray-50">
      <Diamond className="h-4 w-4 text-[#00B259]" />
      {children}
    </p>
  );
}

function ListedAt({ datetime }: { datetime: string }) {
  return (
    <p className=" flex items-center font-diablo text-[12px] font-bold text-gray-50">
      <Clock className="mr-1 h-[10px] w-[10px]" />
      {datetime}
      Listed 4 hours ago
    </p>
  );
}

export function Separator({
  className,
  variant = "default",
}: SeparatorProps): JSX.Element {
  return (
    <hr
      className={cn(
        "border-none border-none bg-contain bg-no-repeat",
        variant === "default" &&
          "my-2 h-[5px] bg-[image:url('/images/separators/separator.png')] bg-[length:100%]",
        variant === "item-power" &&
          "my-1 h-[5px] bg-[image:url('/images/separators/half-separator.png')] bg-[length:50%_100%]",
        className,
      )}
    />
  );
}

function InformationsUser({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="mt-2 flex items-center justify-center gap-2">
      {children}
    </div>
  );
}
