"use client";

import { MenuIcon } from "lucide-react";
import { CoinIcon } from "@/components/icons/coins-icon";

export function Details({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="">{children}</div>;
}

Details.Header = Header;
Details.Body = Body;
Details.Title = Title;
Details.Menu = Menu;
Details.Bid = Bid;
Details.Buy = Buy;

function Header({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="relative ">{children}</div>;
}

function Body({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="mt-2 flex justify-center gap-1">{children}</div>;
}

function Menu(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button {...props} type="button" className="absolute right-0 top-3">
      <MenuIcon className="h-4 w-4" />
    </button>
  );
}

function Title() {
  return (
    <p className="grid grid-cols-3 items-center text-center font-diablo text-[12px] uppercase text-gray-300">
      <span className="h-0.5 bg-gray-700" />
      <span>Details</span>
      <span className="h-0.5 bg-gray-700" />
    </p>
  );
}

function Bid() {
  return (
    <div>
      <h6 className="text-center font-diablo text-[12px] uppercase">Bid</h6>
      <div>
        <button
          type="button"
          className="flex h-[27px] w-[110px] items-center justify-center font-diablo text-[14px]  hover:scale-105 hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-blue.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="mr-1 h-[12px] w-[12px] text-yellow-100" />
          100M
        </button>
        <p className="mt-1 text-center font-diablo text-[12px] text-gray-50">
          11 Bids
        </p>
      </div>
    </div>
  );
}

function Buy() {
  return (
    <div>
      <h6 className="text-center  font-diablo text-[12px] uppercase">Buy</h6>
      <div>
        <button
          type="button"
          className=" flex h-[27px] w-[110px] items-center justify-center font-diablo text-[14px]  hover:scale-105 hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="mr-1 h-[12px] w-[12px] text-yellow-100" />
          580M
        </button>
        <p className="mt-1 text-center font-diablo text-[12px] text-gray-50">
          2h:21:47
        </p>
      </div>
    </div>
  );
}
