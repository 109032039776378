import { ParallelogramIcon } from "@/components/icons/parallelogram-icon";
import { StarIcon } from "@/components/icons/star-icon";

export function List() {
  return (
    <ul>
      <li className="flex items-center font-diablo text-[12px] text-gray-100">
        <GreeaterAffixIcon />
        2,620 Maximum Life
      </li>
      <li className="flex items-center font-diablo text-[12px] text-gray-100">
        <GreeaterAffixIcon />
        90% Damage
      </li>
      <li className="flex items-center font-diablo text-[12px] text-gray-100">
        <ParallelogramIcon className="mr-1 h-2 w-2" />
        80% Vulnerable Damage [80 - 100]%
      </li>
      <li className="flex items-start font-diablo text-[12px]">
        <span className="mr-1">
          <StarIcon className="h-2 w-2 text-orange-200" />
        </span>
        <span className="text-orange-200">
          Critical Strikes with <span className="text-gray-50">Shred</span> deal
          <span className="text-purple"> 54%</span>{" "}
          <span className="text-gray-200">[30 - 60]%</span> of the damage dealt
          as Lightning damage to the target and surrounding enemies. (Druid
          Only)
        </span>
      </li>
    </ul>
  );
}

function GreeaterAffixIcon(): JSX.Element {
  return (
    <i className="mr-1 block h-[10px] w-[10px] rounded-full  bg-[image:url('/images/decorations/greater-affix.webp')] bg-cover" />
  );
}
