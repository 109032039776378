import Image from "next/image";
import { Tooltip } from "../tooltip";

export function TrustBadgeIcon(): JSX.Element {
  return (
    <Tooltip.Provider>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Image
            src="/assets/misc/trust-badge.png"
            width={22}
            height={17}
            alt="trust badge icon"
          />
        </Tooltip.Trigger>
        <Tooltip.Content className="max-w-[200px] border-none bg-gray-900 px-1 py-1 text-center font-diablo text-[12px]">
          <p>Trusted Trader with over 1,000 verified transactions</p>
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  );
}
