export function Header({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="relative grid pl-[7%] pr-[75px] pt-4">{children}</div>;
}

Header.Bottom = Bottom;
Header.BottomLeft = BottomLeft;
Header.UserSection = UserSection;

function Bottom({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="grid">{children}</div>;
}

function BottomLeft({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="grid gap-1">{children}</div>;
}

function UserSection({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="flex items-center gap-2">{children}</div>;
}
